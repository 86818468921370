import "./alert-message.css";
import React, { useRef } from "react";
import {CSSTransition} from "react-transition-group";
import checkIcon from "./check_circle.png";
import closeIcon from "./close_circle.png";

interface PropsReference {
    isVisible: boolean,
    message: string,
    success: boolean
}

function AlertMessage(props: PropsReference){
    const { isVisible, message, success } = props;
    const alertRef = useRef(null);

    return (
        <CSSTransition
            in={isVisible}
            nodeRef={alertRef}
            timeout={200}
            classNames="alert_anim"
            unmountOnExit
        >
            <div className={success ? "alert_modal" : "alert_modal red"} ref={alertRef}>
                <img src={success ? checkIcon : closeIcon} className="alert_modal_icon" alt="icon"/>
                <div className="alert_modal_text">{message}</div>
            </div>
        </CSSTransition>
    );
}
export default AlertMessage;