import React, {useState} from "react";
import "./modal-about-item.css";
import {
    convertStringToArray,
    getArrayById,
    filterSelectableSetions,
    countElementPrice,
    convertArrayToString, isNumeric,
    convertRequestToUserOrderType
} from "../../service-functions/service-functions";
import Modal from "../modal/modal";
import AdditionalsSectionItem from "../../components/additionals-section-item/additionals-section-item";
import { menuArrayReference, authData, userOrderRef } from "../../types";
import { ButtonOrangeProps } from "../../components/button-orange/button-orange";
import { addToOrderRequest } from "../../requests";
import { useAlertMessage } from "../../components/alert-message/AlertMessageContext";
import { RootState } from "../../store";
import { connect } from 'react-redux';

interface PropsReference {
    modalAboutItem: number | null,
    opened: boolean,
    handleClose: () => void,
    handleOpenAuth: () => void,
    handleForceUpdateMenu: () => void,
    handleUpdateUserOrder: (order: userOrderRef[], total: number) => void,

    menuArray: menuArrayReference,
    authData: authData,
    clearAuthHandle: () => void
}

function ModalAboutItem ({ modalAboutItem = null, opened, handleClose, handleForceUpdateMenu, menuArray, authData, handleOpenAuth, handleUpdateUserOrder, clearAuthHandle }: PropsReference){
    const [isLoading, setLoading] = useState(false);
    const [totalItemPrice, setTotalItemPrice] = useState<number|undefined>(undefined);
    const [selectedAdditionals, setSelectedAdditionals] = useState<Array<number[]>>([]);

    const alertShowMessage = useAlertMessage();

    const itemObject = getArrayById(menuArray.menu,modalAboutItem);
    const itemSecAdditionalsList =
        convertStringToArray(itemObject?.additionals)
            .map((item) => getArrayById(menuArray.additionalsSections,item)!);


    const getOnlySelectableElements = (onlyRequireSelect: boolean = false): number[] =>{
        if (!menuArray.isMenuReceived) return []
        return filterSelectableSetions(
            menuArray,
            convertStringToArray(
                getArrayById(menuArray.menu,modalAboutItem)?.additionals
            ),
            onlyRequireSelect
        )
    }
    //filter sections to find only selectable sections for user
    const additionalsToSelect = getOnlySelectableElements();

    const handleModalAboutUpdated = (id: number,additionals: number[]) => {
        if (isLoading) return

        const curentAdditionals = selectedAdditionals.slice();
        curentAdditionals[additionalsToSelect.indexOf(id)] = additionals; //using only selectable sections for user
        setSelectedAdditionals(curentAdditionals);
        setTotalItemPrice(countElementPrice(menuArray,modalAboutItem!,curentAdditionals)!);
    };

    const handleRequest = () => {
        if (itemObject==null || !(Number(itemObject.avalible)===1 && Number(itemObject.deleted)===0)){
            alertShowMessage("Товара нет в наличии", false);
            return;
        }

        if (authData.clientToken==null){
            alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
            handleModalAboutClose();
            handleOpenAuth();
            return;
        }

        for (let i=0;i<getOnlySelectableElements(true).length;i++){
            if (typeof selectedAdditionals[i] != 'object' || selectedAdditionals[i].length==0){
                const additionalsToSelect = filterSelectableSetions(
                    menuArray,
                    convertStringToArray(
                        getArrayById(menuArray.menu,modalAboutItem)!.additionals
                    )
                );

                alertShowMessage(getArrayById(menuArray.additionalsSections,additionalsToSelect[i])!.title, false);
                return;
            }
        }
        setLoading(true);

        addToOrderRequest(modalAboutItem as number,convertArrayToString(selectedAdditionals,true)).then((result) =>{
            handleUpdateUserOrder(convertRequestToUserOrderType(result),Number(result[2]));
            alertShowMessage(`"${itemObject.name}" добавлен в корзину`,true);
            handleModalAboutClose();
        }).catch((error) =>{
            switch (error.message) {
                case '404':
                    alertShowMessage("Товара нет в наличии", false);
                    handleForceUpdateMenu();
                    handleModalAboutClose();
                    break;
                case '401':
                    clearAuthHandle();
                    alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
                    handleModalAboutClose();
                    handleOpenAuth();
                    return;
                default:
                    if (isNumeric(error.message)){
                        alertShowMessage("Server error: "+error.message, false);
                    }else {
                        alertShowMessage('Проверьте подключение к интернету',false);
                    }
            }
        }).finally(() => setLoading(false));
    }
    const handleModalAboutClose = () => {

        //reset all states to default
        setLoading(false);
        setSelectedAdditionals([]);
        setTotalItemPrice(undefined);
        handleClose();
    };

    const buttonActive = itemObject!=null ? (Number(itemObject.avalible)===1 && Number(itemObject.deleted)===0) : true,
        priceForButton = (totalItemPrice!=null ? totalItemPrice : itemObject?.price), //defines price number
        priceTextForButton = priceForButton!=null && Number(priceForButton)!==-1 ? `В корзину ${priceForButton} ₽` : 'Будет позже', //text for button

        buttonModalConfigure: ButtonOrangeProps = {
            text: priceTextForButton,
            isLoading: isLoading,
            isDeactivated: !buttonActive,
            onClick: handleRequest
        };
    return(
        <Modal isVisible={opened}
               closable={!isLoading}
               button={buttonModalConfigure}
               handleClose={handleModalAboutClose}>
            {menuArray.isMenuReceived && itemObject!=null && (
                <>
                    <div className="modal_bottom_left">
                        <img src={itemObject.img}
                             className="modal_bottom_left_item_img" id="modalAbout_img" alt="Menu item"/>
                    </div>
                    <div className="modal_bottom_right">
                        <div className="modal_bottom_right_item_title" id="modalAbout_title">{itemObject.name}</div>
                        <div className="modal_bottom_right_item_about" id="modalAbout_about">{itemObject.about}</div>
                        <div className={`modal_bottom_right_item_delivery_available ${itemObject.delivery_avalible=="0" ? 'act' : ''}`}>ТОЛЬКО САМОВЫВОЗ</div>
                        {itemObject.composition && (
                            <div className="modal_bottom_right_item_composition" id="modalAbout_composition">
                                <b>Состав: </b>{itemObject.composition}
                            </div>
                        )}
                        <div className={`modal_bottom_right_item_additionals_list opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                            {itemSecAdditionalsList.map(itemSecAdditionalsListItem => (
                                <AdditionalsSectionItem
                                    key={'AdditionalsSectionItem'+itemSecAdditionalsListItem.id}
                                    secId={Number(itemSecAdditionalsListItem.id)}
                                    isSelectAllowed={!isLoading}
                                    menuList={menuArray}
                                    onSelectUpdated={handleModalAboutUpdated}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
}
const mapStateToProps = (state: RootState) => ({
    menuArray: state.MenuArray,
    authData: state.AuthData
});
export default connect(mapStateToProps)(ModalAboutItem);