import "./input-text.css";
import React, {useState} from "react";

interface PropsReference {
    type: "text"|"password",
    value:string
    placeholder?: string,
    onChangeText: (text: string) => void,
    fill: boolean
}

function InputText({ type, placeholder, onChangeText, fill, value }: PropsReference){

    const onChange = (e: React.FormEvent<HTMLInputElement>) =>{
        const text = e.currentTarget.value;
        onChangeText(text);
    }

    return <input type={type} placeholder={placeholder} onChange={onChange} value={value} className={`${fill ? 'fill_space' : ''}`}/>;
}

export default InputText;