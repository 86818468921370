import "./modal-my-orders.css";
import Modal from "../modal/modal";
import React, { useState, useEffect } from "react";
import { useAlertMessage } from "../../components/alert-message/AlertMessageContext";
import {userOrderRef} from "../../types";
import OrderItem from "./order-item";
import OrderSkeletonItem from "./order-skeleton-item";
import { repeatOrderRequest, getMyOrdersRequest } from "../../requests";
import {
    convertRequestToUserOrderType,
    convertStringToArray,
    getArrayById,
    isNumeric
} from "../../service-functions/service-functions";
import frenchFries from "./french_fries.png";

interface order{
    id:number,
    type:0|1,
    order:string,
    additionals:string,
    date:string,
    status:number,
    totalprice:number,
    address:string,
    user_comment:string,
    cancel_comment:string,
    pay_mobile:string,
    pay_id:string,
    pay_url:string,
    user_id:number,
}
interface PropsReference {
    opened: boolean,
    handleUpdateUserOrder: (order: userOrderRef[], total: number) => void,
    handleOpenCartModal: () => void,
    handleClose: () => void,
    handleOpenAuth: () => void,
    clearAuthHandle: () => void
}

function ModalMyOrders({ opened, handleClose, handleOpenAuth, clearAuthHandle, handleUpdateUserOrder, handleOpenCartModal }: PropsReference){
    const [ordersList, setOrdersList] = useState<order[]|undefined>(undefined);
    const [isLoading, setLoading] = useState(false);
    const [timerUpdateId,setTimerUpdateId] = useState<null | number>(null);

    const alertShowMessage = useAlertMessage();

    const handleModalCartClosed = () => {

        //reset all states to default
        setLoading(false);
        setOrdersList(undefined);
        handleClose();
    };

    const requestGetOrders = () =>{
        setLoading(true);
        getMyOrdersRequest("all").then((result) =>{
            setOrdersList(result as order[]);
            if (timerUpdateId==null){
                setTimerUpdateId(setInterval(requestGetActiveOrders, 15000) as unknown as number);
            }
        }).catch((error) =>{
            if (isNumeric(error.message)){
                alertShowMessage("Server error: "+error.message, false);
            }else {
                alertShowMessage('Проверьте подключение к интернету',false);
                handleModalCartClosed();
            }
        }).finally(() => setLoading(false));
    }
    const requestGetActiveOrders = () =>{
        getMyOrdersRequest("active").then((result) =>{
            const activeOrders= result as order[];
            setOrdersList(prevState => prevState?.slice()?.map(orderItem =>{
                const trySearch = getArrayById(activeOrders,orderItem.id);
                if (trySearch!=null){
                    return trySearch;
                }
                return orderItem;
            }));
        }).catch((error) =>{
            if (isNumeric(error.message)){
                alertShowMessage("Server error: "+error.message, false);
            }else {
                alertShowMessage('Проверьте подключение к интернету',false);
                handleModalCartClosed();
            }
        });
    }

    const requestRepeatOrder = (id: number) => {
        if (!isLoading){
            setLoading(true);
            repeatOrderRequest(id).then((result) =>{
                handleUpdateUserOrder(convertRequestToUserOrderType(result),Number(result[2]));
                alertShowMessage("Заказ скопирован в корзину", true);
                handleOpenCartModal();
                handleModalCartClosed();
            }).catch((error) =>{
                switch (error.message) {
                    case '401':
                        alertShowMessage("Войдите в аккаунт", false);
                        handleModalCartClosed();
                        clearAuthHandle();
                        handleOpenAuth();
                        return;
                    case '404':
                        alertShowMessage("Заказ не найден", false);
                        return;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false))
        }
    }

    useEffect(() =>{
        if (opened){
            requestGetOrders();
        }else {
            if (timerUpdateId!=null){
                clearInterval(timerUpdateId);
                setTimerUpdateId(null);
            }
        }
    },[opened]);
    return(
        <Modal isVisible={opened}
               closable={!isLoading}
               handleClose={handleModalCartClosed}>
            <center className={`orders_items_area opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                {isLoading && ordersList===undefined && <center>
                    {Array(5).fill(undefined).map((item,index) => <OrderSkeletonItem key={'skel_'+index}/>)}
                </center>}
                {typeof ordersList == 'object' && ordersList.length==0 && <center className="orders_empty">
                    <img src={frenchFries} className="cart_empty_img"/>
                    <div className="cart_empty_text">У вас еще не было заказов</div>
                </center>}
                {ordersList?.slice()?.reverse()?.map(item => {
                    const { type, date, id, status, totalprice, order, additionals, cancel_comment, pay_url } = item;
                    const orderCart = convertRequestToUserOrderType([convertStringToArray(order),convertStringToArray(additionals)]);
                    return <OrderItem
                        key={`order_${id}`}
                        type={type}
                        date={date}
                        cancelComment={cancel_comment}
                        orderId={id}
                        status={status}
                        total={totalprice}
                        payUrl={pay_url}
                        order={orderCart}
                        buttonEnabled={!isLoading}
                        handleRepeatOrder={requestRepeatOrder}
                    />
                })}
            </center>
        </Modal>);
}

export default ModalMyOrders;