import { UPDATE_MENU_LIST } from '../constants';
import { menuArrayReference, ReduxMenuAction } from "../types";
import {isValueInstalled} from "../service-functions/service-functions";

const initialData = (window as any).__INITIAL_DATA__;
const menuArray = isValueInstalled(initialData) ? JSON.parse(initialData) : null;

const initialState: menuArrayReference = {
    menu:menuArray ? menuArray['catalog_main'] : [],
    menuSections:menuArray ? menuArray['catalog_sections'] : [],
    additionals:menuArray ? menuArray['catalog_additionals'] : [],
    additionalsSections:menuArray ? menuArray['catalog_additionals_sections'] : [],
    isMenuReceived:menuArray!=null
};

const MenuArray = (state = initialState, { type, menu, menuSections, additionals, additionalsSections }: ReduxMenuAction): menuArrayReference => {
    switch (type) {
        case UPDATE_MENU_LIST :
            return {
                menu:menu,
                menuSections:menuSections,
                additionals:additionals,
                additionalsSections:additionalsSections,
                isMenuReceived:true
            };
        default:
            return state;
    }
}

export default MenuArray;