import './main-banner.css';
import React, {useState, useEffect} from "react";
import {getCafeStatus} from "../../requests";
import { useAlertMessage } from "../alert-message/AlertMessageContext";
import {isNumeric} from "../../service-functions/service-functions";
function MainBanner() {

    const [isTemporaryClosed,setIsTemporaryClosed] = useState<undefined|boolean>(undefined);
    const [isCafeOpened,setIsCafeOpened] = useState(true);
    const [timeUntilClosing,setTimeUntilClosing] = useState(10000);
    const [timeUntilOpen,setTimeUntilOpen] = useState(0);
    const [timerUpdateId,setTimerUpdateId] = useState<null | number>(null);
    const [timerRequesterId,setTimerRequesterId] = useState<null | number>(null);

    const alertShowMessage = useAlertMessage();
    const updateComponentStatus = () => {
        const date = new Date();
        const { isOpened, timeUntilClosing, timeUntilOpen } = cafeStatus(date);
        setIsCafeOpened(isOpened);
        setTimeUntilClosing(timeUntilClosing);
        setTimeUntilOpen(timeUntilOpen);
    }

    const RequestCafeStatus = () => {
        getCafeStatus().then((result) =>{
            setIsTemporaryClosed(Number(result['cafe_work']) == 0);
        }).catch((error) =>{
            if (isNumeric(error.message)){
                alertShowMessage("Ошибка при попытке получения статуса работы кафе: Server error: "+error.message, false);
                if (timerRequesterId != null){
                    clearInterval(timerRequesterId);
                }
                console.log(error);
            }
        })
    }

    const cafeStatus = (currentTime: Date) => {
        const openingTime = new Date(currentTime);
        openingTime.setHours(11, 0, 0, 0); // Set the opening time of the cafe (11:00 am)

        const closingTime = new Date(currentTime);
        closingTime.setHours(21, 45, 0, 0); //Set the closing time of the cafe (21:45 pm)

        if (currentTime >= openingTime && currentTime <= closingTime) {
            return {
                isOpened: true,
                timeUntilClosing: Math.floor((closingTime.getTime() - currentTime.getTime()) / 1000),
                timeUntilOpen: 0
            };
        } else if (currentTime < openingTime) {
            return {
                isOpened: false,
                timeUntilClosing: 0,
                timeUntilOpen: Math.floor((openingTime.getTime() - currentTime.getTime()) / 1000)
            };
        } else {
            const nextOpeningTime = new Date(currentTime);
            nextOpeningTime.setDate(nextOpeningTime.getDate() + 1);
            nextOpeningTime.setHours(11, 0, 0, 0); // Next cafe opening time (11:00 am the next day)

            return {
                isOpened: false,
                timeUntilClosing: 0,
                timeUntilOpen: Math.floor((nextOpeningTime.getTime() - currentTime.getTime()) / 1000)
            };
        }
    }
    const convertSecondsToHoursAndMinutes = (seconds: number) => {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);

        if (hours > 0 && minutes > 0) {
            return `${hours}ч ${minutes}мин`;
        } else if (hours > 0) {
            return `${hours}ч`;
        } else if (minutes > 0) {
            return `${minutes}мин`;
        } else {
            return 'меньше минуты';
        }
    }

    useEffect(() =>{
        setTimerUpdateId(setInterval(updateComponentStatus, 1000) as unknown as number);
        setTimerRequesterId(setInterval(RequestCafeStatus, 30000) as unknown as number);
        RequestCafeStatus();
        return () =>{
            if (timerUpdateId != null) clearInterval(timerUpdateId);
            if (timerRequesterId != null) clearInterval(timerRequesterId);
        }
    },[])
    return(<>
        <div className="operating_mode">
            <div className="operating_mode_side">
                <div className="app_download_block_circle">
                    <svg className="operating_mode_side_back">
                        <circle cx="550" cy="290" r="320" stroke="" strokeWidth="3" fill="#c6aff9"></circle>
                    </svg>
                </div>
                <img
                    className="operating_mode_icon"
                    src="/images/ui-icons/material_alarm_clock.png"
                    alt="clock icon"
                />
                {isTemporaryClosed===undefined &&
                    <span className="operating_mode_title skeleton">загрузка</span>}
                {isTemporaryClosed !== undefined && <>
                    <div className="operating_mode_title">
                        Мы сейчас{isCafeOpened && isTemporaryClosed && ' временно'}
                        {isCafeOpened && !isTemporaryClosed ?
                            (<span className="color_green"> открыты</span>):
                            (<span className="color_red"> закрыты</span>)
                        }
                    </div>
                    {!isCafeOpened && (
                        <div className="operating_mode_subtitle color_black">Откроемся через <span className="color_green">{convertSecondsToHoursAndMinutes(timeUntilOpen)}</span></div>
                    )}
                    {!isTemporaryClosed && isCafeOpened && timeUntilClosing<7200 && (
                        <div className="operating_mode_subtitle color_black">Закроемся через <span className="color_red">{convertSecondsToHoursAndMinutes(timeUntilClosing)}</span></div>
                    )}
                </>}
                <div className="operating_mode_subtitle">Работаем ежедневно с 11:00 до 22:00</div>
            </div>
            <img
                className="operating_mode_img"
                src="/images/restaurant/2021-04-12 (3).jpg"
                alt="iBurger кафе бургер и картошка"
            />
        </div>
        <div></div>
    </>);
}
export default MainBanner;