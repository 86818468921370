import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { menuArrayReference, authData } from "./types";

interface ReduxRootState {
    MenuArray: menuArrayReference,
    AuthData: authData
}

const store = configureStore({
    reducer: rootReducer,
    preloadedState: {},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReduxRootState;

export default store;
