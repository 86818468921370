import './header.css';
import ButtonRegularOrange from "../../components/button-regular-orange/button-regular-orange";
import cartIcon from "./orderbag_icon.png";
import {RootState} from "../../store";
import {connect} from "react-redux";
import { authData } from "../../types";

import arrowIcon from "./arrow_bottom_icon.png";
import AvatarIcon from "./avatar_icon.svg";
import myOrdersIcon from "./orders_icon.png";
import logoutIcon from "./exit_icon.png";
import nightThemeIcon from "./night_icon.png";
import HeaderMenuButton from "../../components/header-menu-button/header-menu-button";
import { useAlertMessage } from "../../components/alert-message/AlertMessageContext";
interface PropsReference {
    handleScrollToUp: ()=> void,
    handleScrollToMenu: ()=> void,
    handleDownloadApp: ()=> void,
    openAuthModal: ()=> void,
    openCartModal: ()=> void,
    openMyOrdersModal: ()=> void,
    handleSwitchTheme: ()=> void,
    orderTotal: number,
    authData: authData,
    clearAuthHandle: ()=>void
}

function Header ({ handleScrollToUp, handleScrollToMenu, orderTotal, openAuthModal, openCartModal, authData, clearAuthHandle, openMyOrdersModal, handleDownloadApp, handleSwitchTheme }: PropsReference){

    const alertShowMessage = useAlertMessage();
    const switchTheme = () => {
        alertShowMessage('Вы сменили тему', true);
        handleSwitchTheme();
    }
    const myOrdersHandle = () => openMyOrdersModal();
    const logoutHandle = () => {
        clearAuthHandle();
        alertShowMessage('Вы вышли из аккаунта', true);
    }

    return(
        <div className="header">
            <div>
                <img
                    src="/favicon.ico"
                    className="iblogo"
                    alt="iBurger logo"
                    onClick={handleScrollToUp}
                />
                <div className="iblogo_txt" onClick={handleScrollToUp}>i<span className="color_orange">B</span>urger</div>
                <div className="header_left_nav">
                    <div onClick={handleScrollToMenu}>Меню</div>
                    <div onClick={handleDownloadApp}>Скачать приложение</div>
                </div>
            </div>
            <div>
                {authData.authStatus==false && <ButtonRegularOrange text="Войти в аккаунт" isLoading={false} isDeactivated={false} callbackFunc={openAuthModal}/>}
                {authData.authStatus==true &&
                    <>
                        <div className="header_menu_parent">
                            <button className="header_account_button">
                                <div className="header_account_button_text">{authData.userName}</div>
                                <div className="header_account_button_counter">4</div>
                                <img src={arrowIcon} className="header_account_button_arrow" alt="arrow"/>
                                <img src={AvatarIcon} className="header_account_button_avatar" alt="avatar"/>
                                <div className="header_menu">
                                    <HeaderMenuButton icon={myOrdersIcon} text="Мои заказы" onClick={myOrdersHandle}/>
                                    <HeaderMenuButton icon={nightThemeIcon} text="Ночная тема" onClick={switchTheme}/>
                                    <HeaderMenuButton icon={logoutIcon} text="Выйти" onClick={logoutHandle}/>
                                </div>
                            </button>
                        </div>
                        <button className="header_orderbag_button" onClick={openCartModal}>
                            <img className="header_orderbag_button_img" src={cartIcon} alt="cart"/>
                            <div className="header_orderbag_button_price">{`${orderTotal}₽`}</div>
                        </button>
                    </>
                }
                {authData.authStatus == undefined && <div className="header_right_skeleton"/> }
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    authData: state.AuthData
});
export default connect(mapStateToProps)(Header);