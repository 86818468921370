import "./additional-item.css";
import checkIcon from "./checked_icon.png";

interface propsReference {
    id: number,
    img: string,
    name: string,
    price: number,
    handleClick: (id: number) =>void,
    clickable: boolean,
    checked: boolean
}

function AdditionalItem({ id, img, name, price, handleClick, clickable, checked}: propsReference){

    const itemClick = () => {
        if (clickable){
            handleClick(Number(id));
        }
    }

    return(<div className={`modal_additional_item${!clickable ? ' require' : ''}`} onClick={itemClick}>
        <div
            className="modal_additional_item_left">
            <img src={img}
                 className="modal_additional_item_img"
                 alt="Additional img"
            />
            <div
                className="modal_additional_item_name">{name}
            </div>
            <div
                className="modal_additional_item_price">+{`${price} руб`}
            </div>
        </div>
        <div className={`modal_additional_item_check${checked ? ' act' : ''}`}>
            <img src={checkIcon} className="modal_additional_item_checkimg" alt="checked"/>
        </div>
    </div>);
}
export default AdditionalItem;