import { UPDATE_AUTH_DATA } from '../constants';
import { authData, ReduxAuthAction } from "../types";
import { getCookie } from "../service-functions/service-functions";

const initialState: authData = {
    clientToken:getCookie('authtoken'),
    authStatus:undefined,
    userName:""
};

const AuthData = (state = initialState, { clientToken, type, authStatus, userName }: ReduxAuthAction): authData => {
    switch (type) {
        case UPDATE_AUTH_DATA :
            return {
                clientToken: clientToken,
                authStatus: authStatus,
                userName: userName
            };
        default:
            return state;
    }
}

export default AuthData;