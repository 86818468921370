import React, { createContext, useContext, useEffect, useState } from "react";
import AlertMessage from "./alert-message";

interface PropsReference {
    children?: React.ReactNode
}
interface AlertContextType {
    alertShowMessage: (message: string, isSuccess: boolean) => void;
}
const AlertMessageContext = createContext<AlertContextType|undefined>(undefined);
export function AlertMessageProvider({children}: PropsReference) {
    const [alertTimerId,setAlertTimerId] = useState<number|null>(null);
    const [isAlertSuccess,setIsAlertSuccess] = useState(true);
    const [alertMessage,setAlertMessage] = useState("");
    const resetAlertTimer = () =>{
        if (alertTimerId!=null) clearTimeout(alertTimerId);
        setAlertTimerId(null)
    }
    const setAlertTimer = () =>{
        resetAlertTimer();
        const timerId = setTimeout(() => setAlertTimerId(null), 3000) as unknown as number;
        setAlertTimerId(timerId);
    }

    const alertShowMessage = (message: string, isSuccess: boolean) =>{
        setAlertTimer();
        setAlertMessage(message);
        setIsAlertSuccess(isSuccess);
    }
    useEffect(() =>{
        return () => resetAlertTimer();
    }, []);
    return (
        <AlertMessageContext.Provider value={{alertShowMessage}}>
            {children}
            <AlertMessage isVisible={alertTimerId!=null} message={alertMessage} success={isAlertSuccess}/>
        </AlertMessageContext.Provider>
    );
}
export const useAlertMessage = () => {
    return useContext(AlertMessageContext)!.alertShowMessage;
};