import "./additionals-section-item.css";
import AdditionalItem from "../additional-item/additional-item";
import { convertStringToArray, getArrayById, filterSelectableSetions } from "../../service-functions/service-functions";
import { menuArrayReference } from "../../types";
import React, { useState, useEffect } from "react";

interface propsReference {
    secId: number,
    isSelectAllowed: boolean,
    menuList: menuArrayReference,
    onSelectUpdated: (id: number,additionals: number[]) =>void
}

function AdditionalsSectionItem ({ secId, isSelectAllowed, menuList, onSelectUpdated }: propsReference){

    const [selectedAdditionals,setSelectedAdditionals] = useState<number[]>([]);

    const MenuAdditionals = menuList.additionals;
    const thisSection = getArrayById(menuList.additionalsSections,secId)!;
    const additionalsIdsList = convertStringToArray(thisSection.additionals_list).map((item) => Number(item));
    const additionalsList = additionalsIdsList.map((itemId) => getArrayById(MenuAdditionals,itemId)!);
    const isOneSelect = Number(thisSection.selectrequare)===1;
    const isAllSelected = filterSelectableSetions(menuList,[secId]).length==0;

    const handleAdditionalClick = (id: number) => {

        if (!isSelectAllowed)
            return;

        setSelectedAdditionals(prevState => {
            if (isOneSelect){
                return [id];
            }
            if (prevState.includes(id)){
                return prevState.filter(item => item !== id);
            }
            return [...prevState,id].sort((a, b) => {
                const indexA = additionalsIdsList.indexOf(a);
                const indexB = additionalsIdsList.indexOf(b);
                return indexA - indexB;
            });
        });
    }

    useEffect(() =>{
        onSelectUpdated(secId, selectedAdditionals);
    }, [selectedAdditionals])

    return <>
        <div className="modal_bottom_right_item_additionals_list_title">{thisSection.title}</div>
        {additionalsList.map((additionalsListItem) => {
                const { id, img, name, price, checked } = additionalsListItem;
                return <AdditionalItem
                    key={'AdditionalItem'+id}
                    id={Number(id)}
                    img={img}
                    name={name}
                    price={Number(price)}
                    checked={isAllSelected || selectedAdditionals.includes(Number(id))}
                    clickable={Number(checked)===0}
                    handleClick={handleAdditionalClick}
                />;
            }
        )}
    </>;
}

export default AdditionalsSectionItem;