import { UPDATE_MENU_LIST } from "../constants";
import {
    menuArrayAdditionalsRef,
    menuArrayAdditionalsSecRef,
    menuArrayMenuRef,
    menuArrayMenuSecRef,
    ReduxMenuAction
} from "../types";

export const updateMenu = (menu: menuArrayMenuRef[], menuSections: menuArrayMenuSecRef[], additionals: menuArrayAdditionalsRef[], additionalsSections: menuArrayAdditionalsSecRef[]): ReduxMenuAction => ({
    type:UPDATE_MENU_LIST,
    menu:menu,
    menuSections:menuSections,
    additionals:additionals,
    additionalsSections:additionalsSections,
    isMenuReceived:true
});