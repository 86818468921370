import "./header-menu-button.css";

interface PropsReference {
    icon: string,
    text: string,
    onClick: () => void
}
function HeaderMenuButton({ icon, text, onClick }: PropsReference) {

    return <button className="header_menu_item" onClick={onClick}>
        <img src={icon} className="header_menu_item_img" alt="icon"/>
        <div className="header_menu_item_text">{text}</div>
    </button>;
}
export default HeaderMenuButton;