import "./AppUser.css";
import Header from "../header/header";
import MainBanner from '../../components/main-page-banner/main-banner';
import MapWidget from "../../components/map-widget/map-widget";
import InteractiveCard from "../../components/interactive-card/interactive-card";
import ButtonWhite from "../../components/button-white/button-white";
import ModalAboutItem from "../modal-about-item/modal-about-item";
import ModalLogIn from "../modal-log-in/modal-log-in";
import ModalLogUp from "../modal-log-up/modal-log-up";
import ModalCart from "../modal-cart/modal-cart";
import ModalConfirmOrder from "../modal-confirm-order/modal-confirm-order";
import MenuRenderer from "../menu-renderer/menu-renderer"
import {
    menuArrayReference,
    authData,
    userOrderRef,
    menuArrayMenuRef,
    menuArrayMenuSecRef,
    menuArrayAdditionalsRef, menuArrayAdditionalsSecRef
} from "../../types";
import { RootState } from "../../store";
import React, { useState, useEffect, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { connect } from 'react-redux';

import { updateMenu } from "../../actions/update-menu-array";
import {updateClientAuth} from "../../actions/update-auth-data";

import AppDownloadImg from "./phone_app.png";
import DeliveryCompositionImg from "./delivery_bag_card_composition.png";
import CreateWokYourselfImg from "./create_wok_yourself.png";
import CreateHotDogYourselfImg from "./createityourself1_notext.png";
import {getMenuArrayRequest} from "../../requests";
import { useAlertMessage } from "../../components/alert-message/AlertMessageContext";
import {
    convertRequestToUserOrderType,
    getArrayById, getCookie,
    isValueInstalled,
    removeCookie, setCookie
} from "../../service-functions/service-functions";
import ModalMyOrders from "../modal-my-orders/modal-my-orders";
import Footer from "./footer";
import {Helmet} from "react-helmet-async";

interface PropsReference {
    menuArray: menuArrayReference,
    updateMenu: (menu: menuArrayMenuRef[], menuSections: menuArrayMenuSecRef[], additionals: menuArrayAdditionalsRef[], additionalsSections: menuArrayAdditionalsSecRef[])=>void,
    updateClientAuth: (clientToken:string|null, authStatus:undefined|boolean, userName:string) => void,
    authData: authData
}

function AppUser({ menuArray, updateMenu, authData, updateClientAuth }:PropsReference) {

    const getInitialTheme = () => {
        const cookie = getCookie('theme');
        if (cookie!=null) return cookie=='dark'
        return window.matchMedia("(prefers-color-scheme: dark)").matches;
    };
    const [isDarkTheme, setIsDarkTheme] = useState(getInitialTheme());
    const [clickedItemId, setClickedItemId] = useState<null | number>(null);
    const [isModalAboutOpened, setIsModalAboutOpened] = useState<boolean>(false);
    const [isModalLogInOpened, setIsModalLogInOpened] = useState<boolean>(false);
    const [isModalLogUpOpened, setIsModalLogUpOpened] = useState<boolean>(false);
    const [isModalCartOpened, setIsModalCartOpened] = useState<boolean>(false);
    const [isModalMyOrdersOpened, setIsModalMyOrdersOpened] = useState<boolean>(false);
    const [isModalConfirmOpened, setIsModalConfirmOpened] = useState<boolean>(false);
    const [userOrder, setUserOrder] = useState<userOrderRef[]>([]);
    const [orderTotal, setOrderTotal] = useState<number>(0);
    const [timerUpdateId,setTimerUpdateId] = useState<null | number>(null);

    const [userDefaultAddress, setUserDefaultAddress] = useState<string>("");
    const [deliveryConditionsText, setDeliveryConditionsText] = useState<string>("");

    const { clientToken } = authData;
    const alertShowMessage = useAlertMessage();
    const location = useLocation();
    const navigate = useNavigate();

    const menuRef = useRef<HTMLHeadingElement | null>(null);

    const handleOpenAboutModal = (id: number) => navigate(`?dish=${id}`);
    const handleCloseAboutModal = () => {
        navigate('');
        setIsModalAboutOpened(false);
    }
    const handleSwitchTheme = () => {
        const newVal = isDarkTheme ? 'light' : 'dark';
        setCookie('theme',newVal,2592000,'/');
        setIsDarkTheme(prevState => !prevState);
    }
    const handleOpenLogInModal = () => setIsModalLogInOpened(true);
    const handleCloseLogInModal = () => setIsModalLogInOpened(false);
    const handleOpenLogUpModal = () => setIsModalLogUpOpened(true);
    const handleCloseLogUpModal = () => setIsModalLogUpOpened(false);
    const handleOpenCartModal = () => setIsModalCartOpened(true);
    const handleCloseCartModal = () => setIsModalCartOpened(false);
    const handleOpenConfirmModal = () => setIsModalConfirmOpened(true);
    const handleCloseConfirmModal = () => setIsModalConfirmOpened(false);
    const handleOpenMyOrdersModal = () => setIsModalMyOrdersOpened(true);
    const handleCloseMyOrdersModal = () => setIsModalMyOrdersOpened(false);

    const changeUserOrder = (order: userOrderRef[], total: number) => {
        setUserOrder(order);
        setOrderTotal(total);
    }
    const clearAuth = () =>{
        updateClientAuth(null,false,"");
        removeCookie("authtoken","/");
    }
    const scrollTo = (target: React.RefObject<HTMLHeadingElement|null>|null) => {
        const elem = target?.current;
        const top = elem ?
            elem.getBoundingClientRect().top + window.scrollY : 0;
        window.scrollTo({ top: top, behavior: 'smooth' });
    }

    const handleDownloadApp = () => window.open('/app', '_blank')?.focus();
    const handleScrollToMenu = () => scrollTo(menuRef);

    const getMenu = () =>{
        getMenuArrayRequest().then((result) =>{
            setUserDefaultAddress(result['user_default_address']);
            setDeliveryConditionsText(result['delivery_text']);
            updateMenu(result['catalog_main'],result['catalog_sections'],result['catalog_additionals'],result['catalog_additionals_sections']);
            if (result['authorized'] as boolean){
                updateClientAuth(clientToken, true,result['user_name']);
                const orderData = result['user_order'];
                changeUserOrder(convertRequestToUserOrderType(orderData),Number(orderData[2]));
                if (document.location.hash=='#orders'){
                    handleOpenMyOrdersModal();
                    document.location.hash = '';
                }
            }else {
                updateClientAuth(null, false,"");
            }
            if (timerUpdateId == null) setTimerUpdateId(setInterval(getMenu, 300000) as unknown as number);

        }).catch((error) =>{
            if (error.message=='isNumeric'){
                //TODO figure this alert thing out
                alertShowMessage('Не удалось загрузить меню: ошибка сервера',false);
            }else {
                alertShowMessage('Проверьте подключение к интернету',false);
                getMenu();
            }
        });
    }
    useEffect(() =>{
        getMenu();
        return () =>{
            if (timerUpdateId != null) clearInterval(timerUpdateId);
        }
    },[]);
    useEffect(() =>{
        const queryParams = new URLSearchParams(location.search);
        const dishId = queryParams.get('dish');

        if (dishId){
            if (menuArray.isMenuReceived && getArrayById(menuArray.menu,dishId)){
                setClickedItemId(Number(dishId));
                setIsModalAboutOpened(true);
            }else {
                handleCloseAboutModal();
            }
        }else if (isModalAboutOpened) {
            handleCloseAboutModal();
        }
    },[location]);
    useEffect(() =>{
        const body = document.body;
        if (isDarkTheme){
            if(!body.classList.contains('dark')) body.classList.add('dark');
        }else {
            if(body.classList.contains('dark')) body.classList.remove('dark');
        }
    },[isDarkTheme])

    const selectedDish = menuArray.isMenuReceived && isModalAboutOpened ? getArrayById(menuArray.menu,clickedItemId) : null;
    return (
        <>
            <Helmet>
                <title>{selectedDish ?
                    `${selectedDish.name} ${selectedDish?.about} заказать с доставкой в Лучегорске / iBurger` :
                    'iBurger - доставка еды в Лучегорске / меню'}</title>
                <meta
                    name="description"
                    content={selectedDish ? `${isValueInstalled(selectedDish?.composition) ?
                        `Состав: ${selectedDish?.composition}. ` : ''}${selectedDish?.about} Заказать ${selectedDish.name} онлайн с доставкой на дом. Доставка от 120 рублей` :
                        'Айбургер Лучегорск. Заказ еды онлайн. Привезём к вам домой вкуснейшую еду. Быстро и просто, закажите онлайн через сайт! От бургеров до лапши WOK и картошки фри. Большой ассортимент. Работаем с 11:00 до 22:00 без перерывов и выходных.'}/>
                <meta name="theme-color" content={isDarkTheme ? '#141414' : '#ffffff'}/>
            </Helmet>
            <Header
                handleScrollToUp={() => scrollTo(null)}
                handleScrollToMenu={handleScrollToMenu}
                handleDownloadApp={handleDownloadApp}
                orderTotal={orderTotal}
                openAuthModal={handleOpenLogInModal}
                openCartModal={handleOpenCartModal}
                openMyOrdersModal={handleOpenMyOrdersModal}
                handleSwitchTheme={handleSwitchTheme}
                clearAuthHandle={clearAuth}
            />
            <div className="page_container">
                <h1>Доставка еды в Лучегорске</h1>
                <MainBanner/>
                <MapWidget/>

                <div className="interactive_cards">
                    <InteractiveCard
                        img={AppDownloadImg}
                        title="В приложении удобнее"
                        subtitle="Установите наше приложение и заказывайте еду в пару кликов"
                        accentColor="#9cd1ff"
                        backgroundColor="linear-gradient(90deg, rgb(50 158 251) 0%, rgb(101, 183, 255) 100%)"
                        isLeft={true}
                    >
                        <ButtonWhite text="Скачать" onClick={handleDownloadApp} />
                    </InteractiveCard>
                    <InteractiveCard
                        img={DeliveryCompositionImg}
                        title="Доставка от 120₽*"
                        subtitle="Доставим вам еду в любую точку Лучегорска"
                        accentColor="#ffe84f"
                        backgroundColor="linear-gradient(90deg, rgb(237, 206, 1) 0%, rgb(185 160 0) 100%)"
                        isLeft={false}
                    >
                        <ButtonWhite text="Заказать" isDeactivated={!menuArray.isMenuReceived} onClick={handleScrollToMenu} />
                    </InteractiveCard>
                </div>

                <div className="subtext_alert">*Доставка оплачивается отдельно. Точную стоимость доставки вы можете уточнить в соответствии с тарифом такси. Доставка осуществляется только по Лучегорску.</div>


                <h1>На ваш выбор</h1>
                <div className="interactive_cards">
                    <InteractiveCard
                        img={CreateWokYourselfImg}
                        title="Собери свой WOK"
                        subtitle="Собери свою уникальную коробочку WOK, а мы ее приготовим!"
                        accentColor="#ffa4a4"
                        backgroundColor="linear-gradient(90deg, rgb(255, 0, 0) 0%, rgb(255 69 69) 100%)"
                        isLeft={true}
                    >
                        <ButtonWhite text="Собрать" isDeactivated={!menuArray.isMenuReceived} onClick={() => handleOpenAboutModal(13)} />
                    </InteractiveCard>
                    <InteractiveCard
                        img={CreateHotDogYourselfImg}
                        title="Собери свой Супер-дог"
                        subtitle="Приготовим хот-дог из твоих ингридиентов!"
                        accentColor="#ffaf7d"
                        backgroundColor="linear-gradient(90deg, rgb(255 136 60) 0%, rgb(195, 76, 0) 100%)"
                        isLeft={false}
                    >
                        <ButtonWhite text="Собрать" isDeactivated={!menuArray.isMenuReceived} onClick={() => handleOpenAboutModal(38)} />
                    </InteractiveCard>
                </div>

                <h1 ref={menuRef}>Меню</h1>

                <MenuRenderer menuList={menuArray} onItemClicked={handleOpenAboutModal}/>
            </div>
            <Footer/>
            <ModalAboutItem
                opened={isModalAboutOpened}
                modalAboutItem={clickedItemId}
                handleClose={handleCloseAboutModal}
                handleForceUpdateMenu={getMenu}
                handleOpenAuth={handleOpenLogInModal}
                handleUpdateUserOrder={changeUserOrder}
                clearAuthHandle={clearAuth}
            />
            <ModalLogIn
                opened={isModalLogInOpened}
                handleClose={handleCloseLogInModal}
                handleScrollToMenu={handleScrollToMenu}
                handleUpdateUserOrder={changeUserOrder}
                updateUpdateDefaultAddress={setUserDefaultAddress}
                handleOpenModalLogUp={handleOpenLogUpModal}
            />
            <ModalLogUp
                opened={isModalLogUpOpened}
                handleClose={handleCloseLogUpModal}
                handleScrollToMenu={handleScrollToMenu}
                handleUpdateUserOrder={changeUserOrder}
                handleLogInOpen={handleOpenLogInModal}
            />
            <ModalCart
                opened={isModalCartOpened}
                handleClose={handleCloseCartModal}
                userOrder={userOrder}
                orderTotal={orderTotal}
                handleOpenAuth={handleOpenLogInModal}
                handleForceUpdateMenu={getMenu}
                handleUpdateUserOrder={changeUserOrder}
                handleScrollToMenu={handleScrollToMenu}
                handleOpenConfirmModal={handleOpenConfirmModal}
                clearAuthHandle={clearAuth}
            />
            <ModalConfirmOrder
                opened={isModalConfirmOpened}
                handleClose={handleCloseConfirmModal}
                handleOpenCartModal={handleOpenCartModal}
                handleOpenMyOrdersModal={handleOpenMyOrdersModal}
                totalPrice={orderTotal}
                defaultAddress={userDefaultAddress}
                deliveryConditionsText={deliveryConditionsText}
                handleUpdateUserOrder={changeUserOrder}
                clearAuthHandle={clearAuth}
            />
            <ModalMyOrders
                opened={isModalMyOrdersOpened}
                handleUpdateUserOrder={changeUserOrder}
                handleOpenCartModal={handleOpenCartModal}
                handleClose={handleCloseMyOrdersModal}
                handleOpenAuth={handleOpenLogInModal}
                clearAuthHandle={clearAuth}
            />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    menuArray: state.MenuArray,
    authData: state.AuthData
});
export default connect(mapStateToProps, { updateMenu, updateClientAuth })(AppUser);