import "./modal-cart.css";
import Modal from "../modal/modal";
import React, { useState } from "react";
import { ButtonOrangeProps } from "../../components/button-orange/button-orange";
import { useAlertMessage } from "../../components/alert-message/AlertMessageContext";
import {menuArrayReference, userOrderRef} from "../../types";
import CartRenderer from "../cart-renderer/cart-renderer";
import {RootState} from "../../store";
import {connect} from "react-redux";
import {addToOrderRequest, removeCartItem } from "../../requests";
import {
    convertArrayToString,
    convertRequestToUserOrderType, getArrayById,
    isNumeric
} from "../../service-functions/service-functions";
import frenchFries from "./french_fries.png";

interface PropsReference {
    menuArray: menuArrayReference,
    opened: boolean,
    handleClose: () => void,
    userOrder: userOrderRef[],
    orderTotal: number,
    handleUpdateUserOrder: (order: userOrderRef[], total: number) => void,
    handleForceUpdateMenu: () => void,
    handleOpenAuth: () => void,
    handleScrollToMenu: ()=> void,
    handleOpenConfirmModal: ()=> void,
    clearAuthHandle: () => void
}

function ModalCart({ opened, handleClose, userOrder, orderTotal, handleUpdateUserOrder, handleForceUpdateMenu, handleOpenAuth, menuArray, clearAuthHandle, handleScrollToMenu, handleOpenConfirmModal }: PropsReference){
    const [isLoading, setLoading] = useState(false);

    const alertShowMessage = useAlertMessage();

    const handleModalCartClosed = () => {

        //reset all states to default
        setLoading(false);
        handleClose();
    };
    const handleModalConfirm = () => {
        if (userOrder.length>0)
            handleOpenConfirmModal()
        else
            handleScrollToMenu()

        handleModalCartClosed();
    };

    const addItemHandle = (idInOrder: number) =>{
        if (!isLoading){
            setLoading(true);
            const item = userOrder[idInOrder];
            addToOrderRequest(item.id,convertArrayToString(item.additionals)).then((result) =>{
                handleUpdateUserOrder(convertRequestToUserOrderType(result),Number(result[2]));
            }).catch((error) =>{
                switch (error.message) {
                    case '404':
                        alertShowMessage("Товара нет в наличии", false);
                        const menuItem = getArrayById(menuArray.menu, item.id)!;
                        if (menuItem.avalible=="1"&&menuItem.deleted=="0"){
                            handleForceUpdateMenu();
                        }
                        break;
                    case '401':
                        alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
                        handleModalCartClosed();
                        clearAuthHandle();
                        handleOpenAuth();
                        return;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false));
        }
    }
    const removeItemHandle = (idInOrder: number) =>{
        if (!isLoading){
            setLoading(true);
            removeCartItem(idInOrder).then((result) =>{
                handleUpdateUserOrder(convertRequestToUserOrderType(result),Number(result[2]));
            }).catch((error) =>{
                switch (error.message) {
                    case '401':
                        alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
                        handleModalCartClosed();
                        clearAuthHandle();
                        handleOpenAuth();
                        return;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false));
        }
    }
    const removeAllHandle = () =>{
        if (!isLoading){
            setLoading(true);
            removeCartItem(null).then((result) =>{
                handleUpdateUserOrder(convertRequestToUserOrderType(result),Number(result[2]));
                alertShowMessage("Корзина очищена", true);
            }).catch((error) =>{
                switch (error.message) {
                    case '401':
                        alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
                        handleModalCartClosed();
                        clearAuthHandle();
                        handleOpenAuth();
                        return;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false));
        }
    }

    const buttonModalConfigure: ButtonOrangeProps = {
        text: userOrder.length>0 ? "Оформить заказ": "Перейти в меню",
        isLoading: isLoading,
        isDeactivated: false,
        onClick: handleModalConfirm
    };
    return(
        <Modal isVisible={opened}
               closable={!isLoading}
               button={buttonModalConfigure}
               handleClose={handleModalCartClosed}>
            {userOrder.length == 0 && <center className="orders_empty">
                <img src={frenchFries} className="orders_empty_img" alt="Empty cart icon"/>
                <div className="orders_empty_text">Ваша корзина пуста</div>
            </center>}
            {userOrder.length > 0 &&
                <>
                    <center className={`cart_items_area opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                        <CartRenderer
                            hasButtons={true}
                            userOrder={userOrder}
                            showAvailability={true}
                            showDeliverability={true}
                            addItemHandle={addItemHandle}
                            removeItemHandle={removeItemHandle}
                        />
                    </center>
                    <div className="cart_total_text user_selection_allowed">{`${userOrder.length} блюд на сумму ${orderTotal} рублей`}</div>
                    <div className="cart_clearall_div">
                        <button className="cart_clearall_button" onClick={removeAllHandle}>Очистить корзину</button>
                    </div>
                </>
            }
        </Modal>);
}

const mapStateToProps = (state: RootState) => ({
    menuArray: state.MenuArray
});
export default connect(mapStateToProps)(ModalCart);