import "./modal-log-in.css";
import Modal from "../modal/modal";
import React, { useState } from "react";
import { ButtonOrangeProps } from "../../components/button-orange/button-orange";
import Link from "../../components/link/link";
import InputText from "../../components/input-text/input-text";
import {useAlertMessage} from "../../components/alert-message/AlertMessageContext";
import {
    convertRequestToUserOrderType,
    isNumeric,
    isValueInstalled,
    setCookie
} from "../../service-functions/service-functions";
import { signInRequest } from "../../requests";
import {RootState} from "../../store";
import {connect} from "react-redux";
import {updateClientAuth} from "../../actions/update-auth-data";
import {userOrderRef} from "../../types";

interface PropsReference {
    opened: boolean,
    handleClose: () => void,
    handleOpenModalLogUp: () => void,
    handleScrollToMenu: ()=> void,
    updateClientAuth: (clientToken:string|null, authStatus:undefined|boolean, userName:string) => void,
    updateUpdateDefaultAddress: (address: string) => void,
    handleUpdateUserOrder: (order: userOrderRef[], total: number) => void
}

function ModalLogIn({ opened, handleClose, updateClientAuth, handleUpdateUserOrder, handleOpenModalLogUp, handleScrollToMenu, updateUpdateDefaultAddress }: PropsReference){
    const [isLoading, setLoading] = useState(false);
    const [enteredLogin, setEnteredLogin] = useState<string>("");
    const [enteredPassword, setEnteredPassword] = useState<string>("");

    const alertShowMessage = useAlertMessage();

    const handleModalAuthClosed = () => {

        //reset all states to default
        setLoading(false);
        setEnteredLogin("");
        setEnteredPassword("");
        handleClose();
    };

    const handleRegOpen = () => {
        handleModalAuthClosed();
        handleOpenModalLogUp();
    };

    const handleRequest = () =>{
        if (!isValueInstalled(enteredLogin)){
            alertShowMessage("Введите телефон",false);
        }else if (!isValueInstalled(enteredPassword)){
            alertShowMessage("Введите пароль",false);
        }else {
            setLoading(true);
            signInRequest(enteredLogin,enteredPassword).then((result) =>{
                setCookie("authtoken",result["user_token"],31536000,"/",true);
                updateClientAuth(result["user_token"].toString(), true,result["name"]);
                const userOrder = result['user_order'];
                handleUpdateUserOrder(convertRequestToUserOrderType(userOrder),Number(userOrder[2]));
                updateUpdateDefaultAddress(result["address"]);
                alertShowMessage("Вы успешно вошли в аккаунт",true);
                handleModalAuthClosed();
                handleScrollToMenu();
            }).catch((error) =>{
                switch (error.message){
                    case '401':
                        alertShowMessage("Неверный логин или пароль",false);
                        break;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false));
        }
    }

    const buttonModalConfigure: ButtonOrangeProps = {
        text: "Войти",
        isLoading: isLoading,
        isDeactivated: false,
        onClick: handleRequest
    };
    return(
        <Modal isVisible={opened}
               closable={!isLoading}
               button={buttonModalConfigure}
               handleClose={handleModalAuthClosed}>
            <center className={`opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                <h3 className="modal_auth_title">Вход</h3>
                <InputText type="text" placeholder="Телефон" onChangeText={setEnteredLogin} value={enteredLogin} fill={false}/>
                <InputText type="password" placeholder="Пароль" onChangeText={setEnteredPassword} value={enteredPassword} fill={false}/>
                <div className="modal_auth_subtitle">Нет аккаунта? <Link onClick={handleRegOpen}>Зарегестрироваться</Link></div>
            </center>
        </Modal>);
}

const mapStateToProps = (state: RootState) => ({ });

export default connect(mapStateToProps, { updateClientAuth })(ModalLogIn);