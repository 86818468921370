import "./modal-confirm-order.css";
import Modal from "../modal/modal";
import React, { useState, useEffect } from "react";
import { ButtonOrangeProps } from "../../components/button-orange/button-orange";
import {useAlertMessage} from "../../components/alert-message/AlertMessageContext";
import { isNumeric, isValueInstalled } from "../../service-functions/service-functions";
import { confirmOrderRequest } from "../../requests";
import {userOrderRef} from "../../types";
import InputText from "../../components/input-text/input-text";
import CheckButton from "./check-button";

import bagIcon from "./delivery_bag.png";
import bagIconWithClock from "./delivery_bag_card_composition.png";

interface PropsReference {
    opened: boolean,
    handleClose: () => void,
    handleOpenCartModal: () => void,
    handleOpenMyOrdersModal: () => void,
    totalPrice: number,
    defaultAddress: string,
    deliveryConditionsText: string,
    clearAuthHandle: () => void,
    handleUpdateUserOrder: (order: userOrderRef[], total: number) => void
}

function ModalConfirmOrder({ opened, handleClose, clearAuthHandle, handleUpdateUserOrder, defaultAddress, deliveryConditionsText, totalPrice, handleOpenCartModal, handleOpenMyOrdersModal }: PropsReference){
    const [isLoading, setLoading] = useState(false);
    const [enteredAddress, setEnteredAddress] = useState<string>(defaultAddress);
    const [enteredWish, setEnteredWish] = useState<string>(defaultAddress);
    const [isDelivery, setIsDelivery] = useState(true);

    const alertShowMessage = useAlertMessage();

    const handleModalAuthClosed = () => {

        //reset all states to default
        setEnteredWish("");
        setLoading(false);
        handleClose();
    };

    const handleRequest = () =>{
        if (isDelivery && !isValueInstalled(enteredAddress)){
            alertShowMessage('Введите адрес для доставки',false);
            return;
        }
        setLoading(true);
        confirmOrderRequest(isDelivery ? enteredAddress : '',enteredWish).then((result) =>{
            alertShowMessage('Платеж сформирован. Вы будете перенаправлены на страницу оплаты.',true);
            handleUpdateUserOrder([],0);
            document.location.href = result['confirmation_url'];
            handleOpenMyOrdersModal();
            handleClose();
        }).catch((error) =>{
            switch (error.message) {
                case 'err:order_denied':
                    alertShowMessage('Доставка временно не осуществляется',false);
                    return;
                case 'err:time_out':
                    alertShowMessage('Мы уже закрыты. Откроемся в 11:00.',false);
                    return;
                case 'err:cafe_closed':
                    alertShowMessage('Мы временно не работаем. Приходите завтра :)',false);
                    return;
                case 'err:noDelivItems':
                    alertShowMessage('Удалите из заказа товары, помеченные "Только самовывоз", мы не доставляем их.',false);
                    handleOpenCartModal();
                    handleClose();
                    return;
                case 'err:orderinvalid':
                    alertShowMessage('В вашем заказе есть позиции, которых нет в наличии',false);
                    handleOpenCartModal();
                    handleClose();
                    return;
                default:
                    if (isNumeric(error.message)){
                        if (error.message==401){
                            clearAuthHandle();
                            handleClose();
                        }else {
                            alertShowMessage("Server error: "+error.message, false);
                        }
                    }else {
                        alertShowMessage('Проверьте подключение к интернету',false);
                    }
            }

        }).finally(() => setLoading(false));
    }

    useEffect(() =>{
        if (!isValueInstalled(enteredAddress)) setEnteredAddress(defaultAddress);
    },[defaultAddress])

    const buttonModalConfigure: ButtonOrangeProps = {
        text: "Перейти к оплате",
        isLoading: isLoading,
        isDeactivated: false,
        onClick: handleRequest
    };
    return(
        <Modal isVisible={opened}
               closable={!isLoading}
               button={buttonModalConfigure}
               handleClose={handleModalAuthClosed}>
            <div className={`fill_container opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                <h4>Способ получения</h4>
                <CheckButton icon={bagIcon} text="Самовывоз" checked={!isDelivery}
                             onClick={() => setIsDelivery(false)}/>
                <CheckButton icon={bagIconWithClock} text="Доставка" subtext="оплачивается отдельно"
                             checked={isDelivery} onClick={() => setIsDelivery(true)}/>
                {isDelivery && <>
                    <InputText type="text" placeholder="Адрес, куда доставить" onChangeText={setEnteredAddress}
                               value={enteredAddress} fill={true}/>
                    <div className="modal_confirm_subtitle user_selection_allowed">{deliveryConditionsText}</div>
                </>}
                <div className="modal_confirm_total user_selection_allowed">{`Итого заказ: ${totalPrice} рублей${isDelivery ? ' + доставка' : ''}`}</div>
                <h4>Оплата</h4>
                <div className="modal_confirm_text user_selection_allowed">Оплата производится через партнёра ЮКасса. Вы будете перенаправлены на их сайт для оплаты.</div>
                <InputText type="text" placeholder="Пожелания к заказу" onChangeText={setEnteredWish} value={enteredWish} fill={true}/>
            </div>
        </Modal>);
}

export default ModalConfirmOrder;